var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/nutritionists"
    }
  }, [_vm._v(" Manajemen Ahli Gizi ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Ubah" : "Tambah") + " Ahli Gizi ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px",
      "mb": "50px"
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Ubah" : "Tambah") + " Ahli Gizi ")]), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-w": "590px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-invalid": _vm.$v.email.$invalid,
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Email ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "email",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Email",
      "height": "62px"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.firstName === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nama Depan",
      "height": "62px"
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nama Belakang",
      "height": "62px"
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Foto Ahli Gizi ")]), _c('label', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        bg: 'white',
        cursor: 'pointer',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '6px',
        w: '180px',
        h: '100%',
        minHeight: '180px',
        d: 'flex',
        p: '0',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      },
      expression: "{\n          bg: 'white',\n          cursor: 'pointer',\n          border: '1px solid #C4C4C4',\n          boxSizing: 'border-box',\n          borderRadius: '6px',\n          w: '180px',\n          h: '100%',\n          minHeight: '180px',\n          d: 'flex',\n          p: '0',\n          alignItems: 'center',\n          justifyContent: 'center',\n          overflow: 'hidden',\n        }"
    }],
    attrs: {
      "for": "img-preview"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPreviewImage(_vm.photoUrl),
      "h": !_vm.getPreviewImage(_vm.photoUrl).includes('icon-photo') ? '100%' : '94px',
      "alt": "img program",
      "mx": "auto",
      "border-radius": "6px"
    }
  })], 1), _c('c-input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "img-preview",
      "type": "file",
      "accept": ".jpg, .jpeg, .png",
      "error-border-color": "red.300",
      "height": "62px"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event, 'preview');
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "display": "flex",
      "mb": "10px",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-form-label', {
    attrs: {
      "color": "#555555",
      "font-weigh": "500",
      "font-size": "18px",
      "line-height": "27px",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Pendidikan ")])], 1), _vm._l(_vm.education, function (item, index) {
    return _c('c-box', {
      key: 'edu' + index,
      attrs: {
        "position": "relative"
      }
    }, [_c('c-box', {
      attrs: {
        "position": "absolute",
        "top": "40px",
        "right": "-45px"
      }
    }, [_c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.removeEducation(index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Degre ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "height": "62px",
        "placeholder": "Masukkan Level"
      },
      model: {
        value: item.level,
        callback: function callback($$v) {
          _vm.$set(item, "level", $$v);
        },
        expression: "item.level"
      }
    }, _vm._l(_vm.degre, function (item_) {
      return _c('option', {
        key: item_.value,
        domProps: {
          "value": item_.value
        }
      }, [_vm._v(" " + _vm._s(item_.name) + " ")]);
    }), 0)], 1)], 1), _c('c-form-control', {
      attrs: {
        "is-required": "",
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Gelar ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "placeholder": "Masukkan Gelar",
        "height": "62px"
      },
      model: {
        value: item.title,
        callback: function callback($$v) {
          _vm.$set(item, "title", $$v);
        },
        expression: "item.title"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Universitas ")]), _c('c-input-group', {
      attrs: {
        "size": "md",
        "z-index": "1"
      }
    }, [_c('ModelListSelect', {
      staticStyle: {
        "height": "62px",
        "display": "inline-flex",
        "align-items": "center",
        "font-size": "16px"
      },
      attrs: {
        "list": _vm.dataUniversities,
        "option-value": "id",
        "option-text": "name",
        "placeholder": "Masukkan Universitas"
      },
      model: {
        value: item.university,
        callback: function callback($$v) {
          _vm.$set(item, "university", $$v);
        },
        expression: "item.university"
      }
    })], 1), item.university === '0' ? _c('c-input-group', {
      attrs: {
        "size": "md",
        "mt": "25px"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Universitas",
        "height": "62px"
      },
      on: {
        "keyup": function keyup($event) {
          return _vm.onSetOtherUniversity($event, index);
        }
      },
      model: {
        value: item.otherUniversity,
        callback: function callback($$v) {
          _vm.$set(item, "otherUniversity", $$v);
        },
        expression: "item.otherUniversity"
      }
    })], 1) : _vm._e()], 1)], 1);
  }), _vm.education && _vm.education.length < 2 ? _c('c-button', {
    attrs: {
      "is-disabled": _vm.isEmptyEducation,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add",
      "mb": "25px"
    },
    on: {
      "click": _vm.addEducation
    }
  }, [_vm._v(" Tambah Pendidikan ")]) : _vm._e(), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.rating === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Rating ")]), _c('c-input-group', {
    attrs: {
      "size": "md",
      "mt": "15px"
    }
  }, [_c('StarRating', {
    attrs: {
      "increment": 0.5,
      "star-size": 26,
      "text-class": "hidden"
    },
    model: {
      value: _vm.rating,
      callback: function callback($$v) {
        _vm.rating = $$v;
      },
      expression: "rating"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.quota === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Quota ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Quota",
      "height": "62px"
    },
    model: {
      value: _vm.quota,
      callback: function callback($$v) {
        _vm.quota = $$v;
      },
      expression: "quota"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Spesialisasi / Keahlian ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Spesialisasi / Keahlian",
      "height": "62px"
    },
    model: {
      value: _vm.specialization,
      callback: function callback($$v) {
        _vm.specialization = $$v;
      },
      expression: "specialization"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": ""
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nomor STR ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nomor STR",
      "height": "62px"
    },
    model: {
      value: _vm.str,
      callback: function callback($$v) {
        _vm.str = $$v;
      },
      expression: "str"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Usia Klien ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Usia Klien",
      "height": "62px"
    },
    model: {
      value: _vm.clientAgeHandled,
      callback: function callback($$v) {
        _vm.clientAgeHandled = $$v;
      },
      expression: "clientAgeHandled"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Bahasa ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Bahasa",
      "height": "62px"
    },
    model: {
      value: _vm.languages,
      callback: function callback($$v) {
        _vm.languages = $$v;
      },
      expression: "languages"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "display": "flex",
      "mb": "10px",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-form-label', {
    attrs: {
      "color": "#555555",
      "font-weigh": "500",
      "font-size": "18px",
      "line-height": "27px",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Pengalaman Bekerja ")])], 1), _vm._l(_vm.workExperiences, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "position": "relative"
      }
    }, [_c('c-box', {
      attrs: {
        "position": "absolute",
        "top": "40px",
        "right": "-45px"
      }
    }, [_c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.removeWorkExperience(index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Nama Perusahaan ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Pengalaman Bekerja",
        "height": "62px"
      },
      model: {
        value: item.companyName,
        callback: function callback($$v) {
          _vm.$set(item, "companyName", $$v);
        },
        expression: "item.companyName"
      }
    })], 1)], 1), _c('c-flex', {
      attrs: {
        "align": "center"
      }
    }, [_c('c-form-control', {
      style: {
        width: '50%',
        marginRight: '20px'
      },
      attrs: {
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Tahun Masuk ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Tahun Masuk",
        "height": "62px"
      },
      model: {
        value: item.startYear,
        callback: function callback($$v) {
          _vm.$set(item, "startYear", $$v);
        },
        expression: "item.startYear"
      }
    })], 1)], 1), _c('c-form-control', {
      style: {
        width: '50%'
      },
      attrs: {
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Tahun Keluar ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Tahun Keluar",
        "height": "62px"
      },
      model: {
        value: item.endYear,
        callback: function callback($$v) {
          _vm.$set(item, "endYear", $$v);
        },
        expression: "item.endYear"
      }
    })], 1)], 1)], 1)], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.isEmptyworkExperiences,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add"
    },
    on: {
      "click": _vm.addWorkExperience
    }
  }, [_vm._v(" Tambah Pengalaman Kerja ")]), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "gray",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px",
      "color": "#555555"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "font-size": "18px",
      "is-disabled": _vm.isDisabledSubmit
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Simpan ")])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }